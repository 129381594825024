<template>
  <div class="contents userMgt posts" v-loading="loading">
    <div class="title flexB">
      <h2>리뷰 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">리뷰내용</p>
        <!-- <select v-model="inputMode">
          <option value="comment" disabled>리뷰내용</option>
        </select> -->
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>
      <!-- <div class="flex">
        <p class="bold">카테고리</p>
        <label> <input type="radio" v-model="category" value="" />전체 </label>
        <label>
          <input type="radio" v-model="category" value="SPECIAL" />스페셜
        </label>
        <label>
          <input type="radio" v-model="category" value="TECH" />테크/가전
        </label>
        <label>
          <input type="radio" v-model="category" value="FASHION" />패션/잡화
        </label>
        <label>
          <input type="radio" v-model="category" value="BEAUTY" />뷰티
        </label>
        <label>
          <input type="radio" v-model="category" value="FOOD" />푸드
        </label>
        <label>
          <input type="radio" v-model="category" value="HOMELIVING" />홈리빙
        </label>
        <label>
          <input type="radio" v-model="category" value="TRIP" />여행/레저
        </label>
        <label>
          <input
            type="radio"
            v-model="category"
            value="SPORTS"
          />스포츠/모빌리티
        </label>
        <label>
          <input type="radio" v-model="category" value="CULTURE" />공연/컬쳐
        </label>
        <label>
          <input type="radio" v-model="category" value="KIDS" />베이비/키즈
        </label>
      </div> -->
      <div class="flex">
        <p class="bold">리뷰상태</p>
        <label>
          <input type="radio" v-model="isBlind" :value="false" />전체
        </label>
        <label>
          <input type="radio" v-model="isBlind" :value="true" />블라인드
        </label>
      </div>

      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>

    <div class="box one">
      <h2 class="bold">설문 리스트</h2>
      <table class="review">
        <colgroup>
          <col style="width: 7%" />
          <col style="width: 20%" />
          <col style="width: 9%" />
          <col style="width: 7%" />
          <col style="width: 37%" />
          <col style="width: 13%" />
          <col style="width: 7%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>설문제목</th>
          <th>아이디</th>
          <th>이름</th>
          <th>리뷰내용</th>
          <th>작성일</th>
          <th>관리</th>
        </tr>

        <tr v-for="(data, i) in surveyList" :key="i">
          <td>
            <router-link :to="`/admin/reviews/detail/${data._id}`">
              {{ total + 1 - (i + 1 + (currentPage - 1) * 15) }}</router-link
            >
          </td>
          <td>
            {{ data.post.title }}
          </td>
          <td>
            {{ data.user.email }}
          </td>
          <td>
            {{ data.user.username }}
          </td>
          <td>
            <router-link :to="`/admin/reviews/detail/${data._id}`">{{
              data.comment
            }}</router-link>
          </td>
          <td>
            {{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}
            <!-- {{ moment(data.endAt).format("YYYY.MM.DD") }} -->
          </td>
          <td
            :class="{
              finish: data.isBlind,
            }"
          >
            {{ data.isBlind ? "블라인드" : "-" }}
          </td>
        </tr>
      </table>
      <NoData v-if="surveyList.length == 0" />

      <div class="pagination" v-if="surveyList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="15"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchSurveys } from "@/api/admin";
import { format } from "@/mixins/format";
import NoData from "@/components/contents/Nodata";
export default {
  name: "PostManage",
  mixins: [format],
  components: { NoData },
  data() {
    return {
      moment: moment,
      category: "",
      status: "",
      surveyList: [],
      inputMode: "comment",
      searchWord: "",
      total: 0,
      currentPage: 1,
      sort: "date",
      isBlind: false,
      loading: false,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 3);
  },
  mounted() {
    this.loading = true;

    this.getSurveyList();
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    reset() {
      this.searchWord = "";
      this.category = "";
      this.status = "";
      this.currentPage = 1;
      this.surveyList = [];
      this.total = 0;
      this.isBlind = false;
      this.getSurveyList();
    },

    search() {
      if (this.searchWord == "") {
        return alert("검색어를 입력해 주세요.");
      }
      this.currentPage = 1;
      this.getSurveyList();
    },
    handleMove(id) {
      this.$router.push({ name: "postDetail", params: { id: id } });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSurveyList();
    },

    getSurveyList() {
      let params = {
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        category: this.category,
        isBlind: this.isBlind,
        page: this.currentPage,
        sort: this.sort,
      };
      fetchSurveys(params).then((res) => {
        if (res.data.status == 200) {
          this.surveyList = res.data.data;
          this.total = res.data.total;
          this.currentPage = res.data.page;
          this.loading = false;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
