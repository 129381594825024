import axios from "axios";
import { baseUrl, headers } from "../config/env";

// 회원 관리
export function fetchUserList(params) {
  return axios.get(baseUrl + `/admin/user/list`, { params, headers });
}

export function fetchUserDetails(id) {
  return axios.get(baseUrl + `/admin/user/${id}`, { headers });
}

export function updateUserDetails(data) {
  return axios.put(baseUrl + `/admin/user`, data, { headers });
}

// 대시보드
export function fetchDashboardList() {
  return axios.get(baseUrl + `/admin/dashboard`, { headers });
}

// 설문
export function createPost(data) {
  return axios.post(baseUrl + `/admin/post`, data, { headers });
}
export function fetchPostList(params) {
  return axios.get(baseUrl + `/admin/post/list`, { params, headers });
}
export function fetchPostDetail(id) {
  return axios.get(baseUrl + `/admin/post/${id}`, { headers });
}
export function fetchSurveyList(id, params) {
  return axios.get(baseUrl + `/admin/survey/list/${id}`, { params, headers });
}
export function fetchSurveyDumpList(id) {
  return axios.get(baseUrl + `/admin/survey/dump/list/${id}`, { headers });
}
export function fetchSurveys(params) {
  return axios.get(baseUrl + `/admin/review/list`, { params, headers });
}
export function fetchSurvey(id) {
  return axios.get(baseUrl + `/admin/review/${id}`, { headers });
}
export function createFinish(data) {
  return axios.post(baseUrl + `/admin/post/finish`, data, { headers });
}
export function createBlind(data) {
  return axios.post(baseUrl + `/admin/survey/blind`, data, { headers });
}

export function updatePostDetails(data) {
  return axios.put(baseUrl + `/admin/post`, data, { headers });
}

export function removePost(id) {
  return axios.delete(baseUrl + `/admin/post/${id}`, { headers });
}

export function createBanner(data) {
  return axios.post(baseUrl + `/file/banner`, data, { headers });
}

export function removeBannerImage(params) {
  return axios.delete(baseUrl + `/file/banner`, { params, headers });
}

export function removePostImage(id, params) {
  return axios.delete(baseUrl + `/file/post/${id}`, { params, headers });
}

export function fetchFundingList(id) {
  return axios.get(baseUrl + `/admin/list/${id}`, { headers });
}

export function updateFundingDetails(data) {
  return axios.put(baseUrl + `/admin/funding`, data, { headers });
}
